.home-category-section {
  max-width: 1390px;
  padding: 70px 20px;
  margin: auto;

  .main-title {
    font-family: "Poppins" !important;
    font-size: 24px;
    font-weight: 700;
    text-transform: lowercase;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
    padding-bottom: 30px;
  }

  .item-wrapper {
    overflow-x: hidden;
    padding: 40px 0 0;
    

    .single-item {
      text-decoration: none;
      margin-bottom: 40px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 96px;
        height: 96px;
        transition: all 0.3s ease-in-out;
      }

      .text {
        text-align: center;
        width: 100px;
        margin-top: 20px;
        font-family: "Poppins" !important;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .home-category-section {
    .main-title {
      font-size: 24px;
      padding-bottom: 30px;
    }
  }
}

@media screen and (max-width: 575px) {
  .home-category-section {
    padding: 50px 10px;
  }
}
