.hero-section {
  position: relative;
  background: linear-gradient(
      112.1deg,
      rgba(0, 0, 0, 0.2) 18.42%,
      rgba(0, 0, 0, 0) 99.67%
    ),
    #175c83;
  min-height: 100vh;

  .main-container {
    position: relative;
    max-width: 1390px;
    padding: 0 20px;
    margin: auto;
    display: flex;
    align-items: center;

    .content {
      width: 40%;

      .wordCarousel {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        font-family: "Poppins" !important;
        font-size: 46px;
        // font-size: 32px;
        font-weight: 700;
        color: #f5f5f5;

        div {
          overflow: hidden;
          position: relative;
          float: right;
          height: 65px;
          // padding-top: 10px;
          // margin-top: -2px;
          margin-top: -15px;

          ul {
            padding: 0;
            margin: 0;
          }

          li {
            // padding: 0 10px;
            height: 45px;
            margin-bottom: 45px;
            display: block;

            color: #f5f5f5;
          }
        }
      }

      .flip4 {
        animation: flip4 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
      }

      @keyframes flip4 {
        0% {
          margin-top: -270px;
        }

        5% {
          margin-top: -180px;
        }

        33% {
          margin-top: -180px;
        }

        38% {
          margin-top: -90px;
        }

        66% {
          margin-top: -90px;
        }

        71% {
          margin-top: -0px;
        }

        99.99% {
          margin-top: -0px;
        }

        100% {
          margin-top: -270px;
        }
      }

      .para {
        padding-top: 10px;
        padding-bottom: 40px;
        width: 80%;
        font-family: "Poppins" !important;
        font-size: 18px;
        font-weight: 300;
        color: #f5f5f5;
      }
    }

    .hero-image {
      padding: 10px;
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;

      .zoom-in {
        max-width: 700px;
        animation: zoom-in 0.5s ease-out;
      }

      @keyframes zoom-in {
        from {
          transform: scale(0.3);
        }
        to {
          transform: scale(1);
        }
      }
    }

    .browse-btn {
      text-decoration: none;
      cursor: pointer;
      font-family: "Poppins" !important;
      font-size: 16px;
      font-weight: 500;
      padding: 15px 30px;
      background-color: #ffffff;
      color: #175c83;
      transition: all 0.3s ease-in-out;
      border-radius: 24px;

      img {
        margin-right: 10px;
        height: 15px;
        width: 15px;
      }
    }

    .browse-btn-mbl {
      display: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .hero-section {
    .main-container {
      padding: 40px 20px 80px;

      .content {
        width: 50%;
        .wordCarousel {
          flex-direction: column;
          align-items: flex-start;
          font-size: 36px;

          div {
            padding-top: 0;

            li {
              padding: 0;
            }
          }
        }

        .para {
          width: 100%;
        }
      }

      .hero-image {
        .zoom-in {
          width: 80%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .hero-section {
    min-height: auto;

    .main-container {
      padding: 40px 20px;

      flex-direction: column;
      align-items: flex-start;

      .content {
        width: 100%;

        .wordCarousel {
          width: 100%;
          font-size: 30px;

          display: flex;
          flex-direction: row;
          align-items: center;

          div {
            padding-top: 10px;
            margin-top: -5px;
            height: 60px;

            li {
              padding: 0 10px;
              height: 45px;
              margin-bottom: 45px;
            }
          }
        }

        .para {
          width: 50%;
          font-size: 14px;
          padding: 0;
        }
      }

      .hero-image {
        padding: 0;
        width: 100%;
      }

      .browse-btn {
        font-size: 14px;
      }

      .browse-btn-web {
        display: none;
      }

      .browse-btn-mbl {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .hero-section {
    .main-container {
      padding: 40px 10px;

      .content {
        .wordCarousel {
          flex-direction: column;
          align-items: flex-start;

          div {
            padding-top: 0;

            li {
              padding: 0;
              height: 45px;
              margin-bottom: 45px;
            }
          }
        }

        .para {
          width: 80%;
        }
      }
    }
  }
}
