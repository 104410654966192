.favorite-page {
  //max-width: 1366px;
  max-width: 1920px;
  margin: auto;
  padding: 20px;

  .projects {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .open-project-select {
      margin-right: 20px;

      width: 200px;
      height: 45px;
      padding: 0 20px;

      border: 0.5px solid #175c83;
      box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
      border-radius: 16px;

      outline: none;

      font-family: "Poppins";
      font-weight: 400;
      font-size: 16px;
      color: #000;

      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url('../../assets/images/navbar/down-icon.png');
      background-repeat: no-repeat, repeat;
      background-position: right .7em top 50%, 0 0;
      background-size: .65em auto, 100%;


    }

    .add-project-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 200px;
      height: 45px;

      border-radius: 16px;
      background-color: #175c83;


      span {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 16px;
        //color: #000;
        color: #fff;
      }

      .icon {
        margin-left: 10px;
        font-size: 15px;
      }

      .icon path {
        stroke: #fff;
      }
    }
  }

  .main-wrapper {
    margin-top: 35px;

    .tabs-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      .tab {
        width: 100%;

        cursor: pointer;

        padding: 13px 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-family: "Poppins";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;

        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        border-bottom: 4px solid #f5f5f5;

        .tab-active {
          color: #175c83;
          border-bottom: 4px solid #175c83;
          font-weight: 600;
        }

        span {
          font-family: "Poppins";
          font-size: 18px;
          font-weight: 400;
          color: #313133;
        }

        .close-project {
          font-size: 10px;
          margin-left: 20px;
          margin-right: 3px;
        }

        .icon {
          margin-left: 10px;
          font-size: 17px;
          transform: rotate(45deg);
        }
      }

      .tab-active {
        color: #175c83;
        border-bottom: 4px solid #175c83;
        font-weight: 600;

        span {
          font-weight: 900;
        }
      }
    }

    .favorite-list {
      margin-bottom: 50px;
      //padding: 0 20px;
      padding: 0 0;
      // border: 1px solid rgba(196, 196, 196, .25);
      border: 1px solid #fff;
      border-radius: 0px 5px 5px 5px;
      // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      //box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);

      .single-item {
        display: flex;
        border-bottom: solid 1px #dfdfdf;

        .cross {
          min-width: 80px;

          padding-left: 10px;
          display: flex;
          align-items: center;

          .icon {
            cursor: pointer;
            font-size: 20px;
          }
        }

        .p-image {
          width: 20%;
          height: 120px;

          padding: 15px;

          display: flex;
          align-items: center;

          img {
            max-width: 100%;
            width: 80px;
            max-height: 100%;
          }
        }

        .brand-image {
          width: 25%;

          padding: 15px;


          display: flex;
          align-items: center;

          img {
            max-width: 80px;
            max-height: 45px;
          }
        }

        .p-name {
          width: 30%;

          padding: 15px;


          display: flex;
          align-items: center;

          font-family: "Poppins";
          color: #000;
          font-weight: 400;
          font-size: 15px;
          line-height: 21px;

          span {
            cursor: pointer;

            &:hover {
              text-decoration: underline;
              color: #23527c;
            }
          }
        }


        .download {
          width: 25%;

          padding: 15px;


          display: flex;
          align-items: center;
          justify-content: flex-end;

          .d-btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;
            background: #175c83;
            padding: 4px 7px;
            border-radius: 6px;
            margin-top: 4px; //new

            .icon {
              color: #fff;
              font-size: 20px;
            }
          }
        }
      }

      .no-result {
        padding: 20px 0;
        font-family: "Poppins";
        color: #313133;
        font-weight: 400;
        font-size: 11px;
        text-align: center;
      }
    }


  }

  .share-list-btn {
    margin-left: auto;
    margin-bottom: 10vh;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 200px;
    height: 45px;

    border-radius: 16px;
    background-color: #175c83;
    color: #fff;
    font-family: "Poppins";
  }

  .show-friend-btn {
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px;
    border-radius: 16px;
    background-color: #175c83;
    color: #fff;
    font-family: "Poppins";
    font-size: 12px;

    width: max-content;

    span {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 1420px) {
  .favorite-page {
    padding: 0px 25px;
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .favorite-page {
    padding: 0px 20px;

    .projects {
      .open-project-select {
        height: 40px;
        font-size: 14px;
      }

      .add-project-btn {
        height: 40px;

        span {
          font-size: 14px;
        }
      }
    }

    .main-wrapper {
      .tabs-wrapper {
        .tab {
          width: 160px;
          height: 50px;

          span {
            font-size: 15px;
          }

          .icon {
            margin-left: 8px;
            font-size: 14px;
            transform: rotate(45deg);
          }


        }
      }

      .favorite-list {
        .single-item {
          .cross {
            min-width: 50px;
          }

          .p-image {
            width: 17%;

            img {
              width: 70px;
            }
          }

          .brand-image {
            width: 23%;

            img {
              width: 110px;
            }
          }

          .p-name {
            width: 35%;
            font-size: 14px;
          }

          .download {
            width: 25%;
            padding: 15px;

            // .d-btn {
            //   width: 110px;
            //   height: 35px;
            // }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .favorite-page {

    .main-wrapper {
      .tabs-wrapper {
        padding: 5px 0 0 1px;
        overflow-x: scroll;

        .tab {
          min-width: 160px;
          height: 40px;

          span {
            font-size: 14px;
          }
        }
      }


      .favorite-list {
        .single-item {
          display: flex;
          flex-direction: column;

          .cross {
            min-width: 100px;
            padding: 10px;
            justify-content: flex-end;
          }

          .p-image,
          .p-name,
          .brand-image,
          .download {
            width: 100%;
            padding: 10px;
            justify-content: center;
            text-align: center;
          }

          .p-image {
            height: 150px;

            img {
              width: 130px;
            }
          }

          .download {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .favorite-page {
    padding: 0px 10px;

    .projects {
      flex-direction: column-reverse;

      .open-project-select {
        margin: 0;
        margin-top: 10px;
      }
    }
  }
}