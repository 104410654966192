.modal-component {
  .modal-dialog {
    max-width: 800px;
    padding: 20px;
    margin-top: 10vh;
  }

  .modal-wrapper {
    padding: 18px;
    border-radius: 5px;
    border: 8px none #000000;
    box-shadow: 0px 0px 30px 0px rgb(2 2 2);
    background-color: rgba(255, 255, 255, 1);

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #000000;

      .object {
        font-family: "Poppins" !important;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        padding-right: 20px;
      }

      .name {
        font-family: "Poppins" !important;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .object-download-wrap {
      margin-top: 10px;
      width: 100%;
      color: #000;

      td {
        padding: 13px 18px;
        border-bottom: solid 1px #dddddd50;
      }

      .td1 {
        width: 10%;
      }

      .td2 {
        width: 13%;
      }

      .td3 {
        max-width: 55%;
        word-break: break-all;
      }

      .td4 {
        width: 22%;

        .download {
          cursor: pointer;
          background: #423e3f;
          box-shadow: none;
          color: white;
          text-decoration: none;
          border-radius: 10px;
          border-style: solid;
          font-family: "Poppins" !important;
          font-weight: 400;
          font-size: 12px;
          width: 120px;
          height: 35px;

          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.1s ease-in-out;

          &:hover {
            background: #feb449;
          }
        }
      }

      img {
        width: 30px;
      }
    }

    .m-body {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info {
        cursor: pointer;
        font-family: "Poppins" !important;
        font-size: 14px;
        font-weight: 400;
        color: #337ab7;
        text-decoration: none;

        &:hover {
          color: #23527c;
          text-decoration: underline;
        }
      }

      .brand {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 150px;

        padding-left: 20px;

        img {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    .cross {
      cursor: pointer;
      position: absolute;
      height: 26px;
      width: 26px;

      left: auto;
      right: -13px;
      bottom: auto;
      top: -13px;

      color: #ffffff;

      font-size: 12px;

      border: 2px solid #ffffff;
      border-radius: 26px;
      box-shadow: 0px 0px 15px 1px rgb(2 2 2 / 75%);
      text-shadow: 0px 0px 0px rgb(0 0 0 / 23%);
      background-color: rgba(0, 0, 0, 1);

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .modal-component {
    .modal-dialog {
      padding: 10px;
    }

    .modal-wrapper {
      .m-body {
        align-items: flex-start;
        flex-direction: column;

        .brand {
          padding-left: 0px;
          margin-left: auto;
          margin-top: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .modal-component {
    .modal-wrapper {
      .header {
        flex-direction: column;
        align-items: flex-start;

        .name {
          margin-top: 5px;
        }
      }

      .object-download-wrap {
        font-size: 12px;

        .td1 {
          width: max-content;
        }

        .td2 {
          width: 100%;
          max-width: 100%;
        }

        .td3 {
          display: none;
        }

        .td4 {
          .download {
            width: 70px;
            height: 30px;
            font-size: 10px;
          }
        }

        img {
          width: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .modal-component {
    .modal-wrapper {
      .object-download-wrap {
        td {
          padding: 10px;
        }

        img {
          width: 20px;
        }
      }
    }
  }
}
