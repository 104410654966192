
.description-load-more {
  width: 100%;
  display: flex;
  justify-content: start;

  span {
    cursor: pointer;
    //width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;

    // padding: 13px;
    // border-radius: 16px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #175c83;
    // border: 2px solid #175c83;
  }
}

.slick-next,
.slick-prev:before {
  display: none !important;
}

.bimroom-product-page {
  max-width: 1390px;
  padding: 0 20px;
  margin: auto;
  
  .product-view-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .product-view-brand {
      //margin-bottom: 10px;
      width: 120px;
      height: 60px;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      // img {
      //   max-width: 100%;
      //   width: 100%;
      //   height: 100%;
      // }
    }
  }

  .product-view-detail-section {
    display: flex;
    position: relative;

    .product-view {
      width: 50%;

      .action-buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 50px 0;
        padding-bottom: 20px;

        .favorite-project-wrapper {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .btn {
          width: 100%;

          padding: 13px;
          border-radius: 16px;

          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3ss ease;

          img {
            width: 17px;
          }

          span {
            margin-left: 10px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
          }
        }

        .outlined {
          color: #175c83;
          border: 2px solid #175c83;

          &:hover {
            background-color: rgba(23, 92, 131, 0.1);
          }
        }

        .contained {
          color: #fff;
          background-color: #175c83;

          &:hover {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.3)
              ),
              #175c83;
          }
        }
      }

      .product-view-sticky {
        position: sticky;
        top: 20px;
      }

      .tab-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        .tab {
          width: 100%;

          cursor: pointer;

          padding: 13px 10px;

          display: flex;
          align-items: center;
          justify-content: center;

          font-family: "Poppins";
          font-size: 16px;
          font-weight: 500;
          font-style: normal;

          text-align: center;
          color: rgba(0, 0, 0, 0.5);
          border-bottom: 4px solid #f5f5f5;
        }

        .active {
          color: #175c83;
          border-bottom: 4px solid #175c83;
          font-weight: 600;
        }
      }

      .image-view {
        overflow: hidden;
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 40px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .slider {
        overflow: hidden;

        .slider-image {
          outline: none !important;
          padding: 40px;
          width: 100%;
          height: 400px;

          display: flex !important;
          align-items: center !important;
          justify-content: center !important;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .left-control {
          cursor: pointer;
          position: absolute;
          top: 50%;
          left: 0;
          display: flex;
          align-items: center;
          z-index: 2;
          color: #175c83;
        }

        .right-control {
          cursor: pointer;

          position: absolute;
          top: 50%;
          right: 0;
          display: flex;
          align-items: center;
          z-index: 2;
          color: #175c83;
        }
      }
    }

    .product-detail {
      width: 50%;
      padding: 0 44px;

      .brand {
        width: 120px;
        margin-bottom: 30px;

        img {
          max-width: 100%;
          width: 100%;
        }
      }

      .title {
        color: #313133;
        font-family: "Poppins";
        font-size: 24px;
        font-weight: 500;
        margin-top: 8px;
      }

      .rating-icons {
        margin: 8px 0 14px;
        display: flex;
        align-items: center;

        // .icon {
        //   font-size: 1.2em;
        //   color: #175c83;
        //   margin-right: 1px;
        //   opacity: 0.25;
        // }

        // .active {
        //   opacity: 1;
        // }

        .icon {
          font-size: 1.2em;
          color: #175c83;
          margin-right: 1px;
        }

        .border-star {
          font-size: 1em;
          color: #175c83;
          margin-right: 1px;
        }
      }

      // .action-buttons {
      //   display: flex;
      //   flex-direction: column;
      //   gap: 10px;
      //   margin: 50px 0;

      //   .favorite-project-wrapper {
      //     display: flex;
      //     align-items: center;
      //     gap: 10px;
      //   }

      //   .btn {
      //     width: 100%;

      //     padding: 13px;
      //     border-radius: 16px;

      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     transition: all 0.3ss ease;

      //     img {
      //       width: 17px;
      //     }

      //     span {
      //       margin-left: 10px;
      //       font-family: "Poppins";
      //       font-style: normal;
      //       font-weight: 500;
      //       font-size: 14px;
      //     }
      //   }

      //   .outlined {
      //     color: #175c83;
      //     border: 2px solid #175c83;

      //     &:hover {
      //       background-color: rgba(23, 92, 131, 0.1);
      //     }
      //   }

      //   .contained {
      //     color: #fff;
      //     background-color: #175c83;

      //     &:hover {
      //       background: linear-gradient(
      //           0deg,
      //           rgba(0, 0, 0, 0.3),
      //           rgba(0, 0, 0, 0.3)
      //         ),
      //         #175c83;
      //     }
      //   }
      // }

      .description {
        margin-bottom: 15px;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 14px;
        line-height: 1.42857143;
        color: #313133;
        overflow: hidden;
      }

      .collapsible {
        width: 100%;

        .section-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0;
          border-bottom: 1px solid #d9d9d9;

          .section-title {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            color: #313133;
          }

          .section-icon {
            font-size: 24px;
            margin-top: -10px;
          }
        }

        .content {
          padding-top: 20px;
        }
      }

      .collapsible-active {
        .section-header {
          border-bottom: none;
        }
      }

      .table_title {
        padding-left: 0;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 500;
        //text-transform: uppercase;
        color: #313133;
      }

      .table-responsive {
       // margin-bottom: 20px;

        .table_heading,
        .table_data {
          padding-left: 0;
          font-family: "Poppins";
          font-size: 12px;
          color: #313133;
        }

        .table_heading {
          width: 45%;
        }
      }

      .rating-section {
        margin-bottom: 70px;

        .rating-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 40px 0;

          .rating-title {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            color: #313133;
          }

          .rating-rating {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .rating-wrapper {
              display: flex;
              align-items: center;

              .rating-text {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                color: #313133;
                margin-right: 5px;
              }
            }

            .rating-text {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              color: #313133;
            }
          }
        }

        .rating-progress-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .rating-progress-text {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #313133;
          }

          .rating-progress-line {
            margin-left: 10%;
            margin-right: 10%;
            width: 100%;
            height: 6px;
          }
        }
      }

      .review-section {
        background: rgba(239, 239, 239, 0.3);
        box-shadow: inset 0.97622px 0.97622px 19.5244px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        overflow: hidden;
        margin-bottom: 20px;

        .review-wrapper {
          padding: 20px;

          .review-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .review-title {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              color: #313133;
            }

            .icon {
              cursor: pointer;
              font-size: 1.4em;
              color: #175c83;
              margin-right: 1px;
              opacity: 0.25;
            }

            .active {
              color: #175c83;
              opacity: 1;
            }
          }

          .textarea {
            width: 100%;
            background: transparent;
            border: none;
            outline: none;
          }
        }

        .btn-submit {
          padding: 16px;
          text-align: center;
          background-color: #175c83;
          color: #fff;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
        }
      }

      .comments-section {
        margin-bottom: 20px;

        .comment-wrapper {
          display: flex;
          align-items: flex-start;
          margin-bottom: 20px;
          gap: 40px;

          .comment-detail {
            width: 40%;

            .title {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              color: #3d3d3d;
              word-break: break-all;
            }
          }

          .comment-text {
            margin-top: 5px;
            width: 60%;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #3d3d3d;
          }
        }
      }
    }
  }
}

.related-product-section {
  max-width: 1366px;
  padding: 60px 20px;
  margin: auto;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;

    .title {
      text-transform: uppercase;
      color: #202020;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 52px;
      line-height: 1;
      cursor: pointer;
    }

    .sliderButton {
      display: flex;
      align-items: center;
      gap: 10px;

      .btn-wrapper {
        border: 2px solid #175c83;
        border-radius: 15px;
        padding: 6px;
        cursor: pointer;

        .back,
        .forward {
          color: #175c83;
          font-size: 1.5em;
        }

        &:hover {
          background-color: rgba(23, 92, 131, 0.1);
        }
      }
    }
  }

  .product-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .p_card {
      border-radius: 40px;
      padding: 30px;
      background: #ffffff;
      width: 95% !important;
      transition: all 0.1s ease;
      border: 2px solid #fff;

      .p_body {
        text-decoration: none;
        width: 100%;

        .thumbnail {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 40px;

          img {
            height: 220px;
            object-fit: cover;
          }
        }

        .footer {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 20px;

          .p_title {
            text-align: center;
            font-family: "Poppins";
            font-size: 14px;
            font-weight: 500;
            color: #313133;
            text-align: left;
            height: 40px;

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .actions {
            display: flex;
            align-items: center;
            gap: 10px;
            width: min-content;

            .icon {
              color: #175c83;
              font-size: 20px;
            }
          }
        }
      }

      &:hover {
        cursor: pointer;
        border: 2px solid #175c83;

        .p_body {
          .footer {
            .p_title {
              color: #175c83;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .bimroom-product-page {
    .product-view-detail-section {
      flex-direction: column;
      padding: 0;

      .product-view {
        width: 100%;
      }

      .product-detail {
        width: 100%;
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .bimroom-product-page {
    .go-back {
      margin-top: 30px;
      margin-bottom: 30px;

      span {
        font-size: 18px;
      }
    }

    .product-view-detail-section {
      padding: 0px;

      .product-view {
        .tab-wrapper {
          gap: 7px;

          .tab {
            padding: 7px 0;
            font-size: 16px;
            font-weight: 500;
          }
        }

        .image-view {
          height: 350px;
          padding: 30px;
        }

        .slider {
          overflow: hidden;

          .slider-image {
            padding: 30px;
            height: 350px;
          }
        }
      }

      .product-detail {
        .brand {
          width: 120px;
          margin-bottom: 25px;
        }

        .action-buttons {
          gap: 10px;
          margin: 40px 0;

          .favorite-project-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
          }
        }

        .collapsible {
          .section-header {
            padding: 20px 0;

            .section-title {
              font-size: 20px;
            }

            .section-icon {
              font-size: 20px;
            }
          }

          .content {
            padding-top: 20px;
          }
        }

        .table_title {
          padding-left: 0;
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 500;
          //text-transform: uppercase;
          color: #313133;
        }

        .table-responsive {
          margin-bottom: 20px;

          .table_heading,
          .table_data {
            padding-left: 0;
            font-family: "Poppins";
            font-size: 12px;
            color: #313133;
          }

          .table_heading {
            width: 45%;
          }
        }

        .rating-section {
          margin-bottom: 50px;

          .rating-header {
            align-items: flex-start;

            .rating-title {
              font-size: 20px;
            }

            .rating-rating {
              .rating-wrapper {
                .rating-text {
                  font-size: 20px;
                }
              }

              .rating-text {
                font-size: 14px;
              }
            }
          }

          .rating-progress-wrapper {
            margin-bottom: 15px;

            .rating-progress-text {
              font-size: 14px;
            }
          }
        }

        .review-section {
          margin-bottom: 20px;

          .review-wrapper {
            padding: 20px;

            .review-header {
              .review-title {
                font-size: 16px;
              }

              .icon {
                font-size: 1.1em;
                color: #175c83;
              }
            }

            .textarea {
              font-size: 14px;
            }
          }

          .btn-submit {
            padding: 16px;
            text-align: center;
            background-color: #175c83;
            color: #fff;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
          }
        }

        .comments-section {
          margin-bottom: 20px;

          .comment-wrapper {
            flex-direction: column;
            margin-bottom: 30px;
            gap: 10px;

            .comment-detail {
              width: 100%;
            }

            .comment-text {
              margin-top: 0px;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .related-product-section {
    padding: 60px 20px;

    .header-container {
      margin-bottom: 40px;

      .title {
        font-size: 22px;
      }

      .sliderButton {
        display: flex;
        align-items: center;
        gap: 10px;

        .btn-wrapper {
          border: 2px solid #175c83;
          border-radius: 15px;
          padding: 6px;
          cursor: pointer;

          .back,
          .forward {
            color: #175c83;
            font-size: 1.5em;
          }
        }
      }
    }

    .product-wrapper {
      .p_card {
        width: 100% !important;

        .p_body {
          text-decoration: none;
          width: 100%;

          .thumbnail {
            margin-bottom: 30px;

            img {
              height: 200px;
            }
          }

          .footer {
            .actions {
              .icon {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .bimroom-product-page {
    padding: 0 10px;

    .product-view-detail-section {
      .product-view {
        .tab-wrapper {
          flex-direction: column;
        }
      }
    }
  }

  .related-product-section {
    padding: 60px 10px;
  }
}
