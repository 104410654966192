html,
body { height: 100%;
}

.unauthenticated-section {
  
  // margin: 0 auto;
  // width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  .signin-btn {
    text-decoration: none;
    cursor: pointer;
    font-family: "Poppins" !important;
    max-width: 120px;
    height: 50px;
    font-size: 14px;
    font-weight: 400;
    padding: 15px 30px;
    color: #fff;
    transition: all 0.3s ease-in-out;
    border-radius: 0.5rem;
    margin: auto;
    text-align: center;
    background-image: none;
    background-color: #175c83;
    border: none;
    touch-action: manipulation;
    user-select: none;
    -moz-user-select: none;
    -ms-touch-action: manipulation;
    -ms-user-select: none;
    -webkit-user-select: none;
    box-shadow: 0 0 30px 0 #dae1f7;
    
    &:hover {
      box-shadow: none;
      background-color: #175c83;
      color: #fff;
    }

  }

  .unauthenticated-container {
    width: 400px;
    height: 250px;
    background-color: #FFFFFF;
    margin: auto;
    //display: flex;
    border: 1px solid transparent;
    border-radius: 4px;
    //justify-content: center;
    //align-items: center;
   // border: 3px solid green;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -125px;
    margin-left: -200px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
   // box-shadow: 0 0 30px 0 #dae1f7
   //border: 1px solid #605e5c;
  }

  .welcome-text {
    width: 100%;
    text-align: center;
    font-family: "Poppins" !important;
    font-weight: 400;
  }

  .broom-logo {
    //width: 100%;
    //margin: auto;
    height: 120px;
    display: flex;
    justify-content: center;
  }
}

