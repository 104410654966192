.navbar-search-drawer {
  padding: 40px 20px;
  height: calc(100% - 56.49px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #175c83;

  .categories {
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    padding: 0 15px;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;

    .category-select {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;

      .text {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #fff;
      }

      .icon {
        color: #fff;
      }
    }

    .drop-down-open {
      position: absolute;
      z-index: 2;
      top: 40px;
      left: 0;
      right: 0;
      max-height: 35vh;
      overflow-y: auto;

      background-color: #fff;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
      border-radius: 12px;

      padding: 15px;

      transition: all 0.1s ease;

      .menu-link {
        border-radius: 4px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        transition: all 0.1s ease;
        cursor: pointer;

        margin-bottom: 5px;

        img {
          width: 20px;
        }

        .label {
          margin-left: 10px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #313131;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .active {
        background-color: #175c83;

        .label {
          color: #fff;
        }
      }
    }

    .drop-down-close {
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }

  .search-filter {
    display: flex;
    align-items: center;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    overflow: hidden;

    input {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      background-color: transparent;
      color: #fff;
      padding: 0 15px;

      &::placeholder {
        color: #fff;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 7px 20px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        #175c83;
      color: #fff;
    }
  }
}
