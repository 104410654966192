.web-navbar {
  background-color: #fff;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1390px;
  padding: 5px 20px;

  margin: auto;

  transition: all 0.3s ease-in-out;
  min-height: 80px;

  .logo {
    transition: all 0.3s ease-in-out;
    width: 160px;

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .center {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 10px;

    .categories {
      height: 40px;
      padding: 0 7px;
      position: relative;
      display: flex;
      align-items: center;

      .category-select {
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: #fff;
        padding: 4px 5px;
        border-radius: 10px;
      }

      .text {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #175c83;
      }

      .down-icon {
        width: 12px;
        margin-left: 7px;
      }

      .drop-down-open {
        position: absolute;
        z-index: 2;
        top: 40px;
        left: 0;

        background-color: #fff;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
        border-radius: 15px;

        padding: 20px;

        transition: all 0.1s ease;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 10px;

        .menu-link {
          border: 1px solid #175c83;
          border-radius: 5px;
          padding: 5px 10px;
          display: flex;
          align-items: center;
          transition: all 0.1s ease;
          cursor: pointer;

          img {
            width: 40px;
          }

          .label {
            margin-left: 10px;
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #313131;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 110px;
          }

          &:hover {
            background-color: #175c83;

            .label {
              color: #fff;
            }
          }
        }

        .active {
          background-color: #175c83;

          .label {
            color: #fff;
          }
        }
      }

      .drop-down-close {
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }

    .search-filter {
      display: flex;
      justify-content: center;

      input {
        width: 300px;
        background: transparent;
        outline: none;
        border: none;
        font-family: "Poppins", Sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #a8a8a9;
        padding: 0.7em;
      }

      .icon {
        border-radius: 0 10px 10px 0;
        cursor: pointer;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #fff;
        background: #175c83;
        border-color: #175c83;
        transition: all 0.3s ease;

        &:hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.3),
              rgba(0, 0, 0, 0.3)
            ),
            #175c83;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .projects-wrapper {
      height: 40px;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;

      .text {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #175c83;
      }
    }

    .login {
      cursor: pointer;
      margin-left: 40px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #175c83;
    }

    .profile-wrapper {
      height: 40px;
      margin-left: 40px;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;

      .profile {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #175c83;
        padding: 1px;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid #175c83;
          object-fit: cover;
        }
      }
    }

    .down-icon {
      width: 24px;
      margin-left: 7px;
      color: #175c83;
    }

    .drop-down-open {
      position: absolute;
      z-index: 2;
      top: 50px;
      right: 0;

      background-color: #fff;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
      border-radius: 12px;

      padding: 5px 0;

      transition: all 0.1s ease;

      .menu-link {
        margin: 0 10px;
        padding: 7px 10px;
        display: flex;
        align-items: center;
        transition: all 0.1s ease;

        img {
          width: 15px;
        }

        .label {
          margin-left: 10px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #313131;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 110px;
        }

        &:hover {
          border-radius: 8px;
          background-color: #175c83;

          .label {
            color: #fff;
          }
        }
      }

      .active {
        background-color: #175c83;
        .label {
          color: #fff;
        }
      }
    }

    .drop-down-close {
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }
}

.home-navbar {
  background-color: transparent;

  .center {
    background-color: rgba(255, 255, 255, 0.1);

    .search-filter {
      input {
        color: rgba(255, 255, 255, 0.6);

        &::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .right {
    .projects-wrapper {
      .text {
        color: #fff;
      }
    }

    .login {
      color: #fff;
    }

    .profile-wrapper {
      .profile {
        border: 1px solid #fff;

        img {
          border: 1px solid #fff;
        }
      }
    }

    .down-icon {
      color: #fff;
    }
  }
}

@media screen and (max-width: 991px) {
  .web-navbar {
    min-height: 70px;

    .center {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .web-navbar {
    display: none;
  }
}
