.load-more-component {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  .show-more {
    cursor: pointer;
    width: max-content;
    margin: 0 20px;
    display: flex;
    align-items: center;

    .text {
      width: max-content;
      font-family: "Poppins" !important;
      font-size: 18px;
      font-weight: 500;
      color: #000000;
    }

    .down-icon {
      width: 12px;
      margin-right: 13px;
      transform: rotate(0deg);
      transition: all 0.1s ease;
    }

    .up-icon {
      width: 12px;
      margin-right: 20px;
      transform: rotate(180deg);
      transition: all 0.1s ease;
    }
  }
}

@media screen and (max-width: 991px) {
  .load-more-component {
    display: flex;
    align-items: center;

    .show-more {
      margin: 0 10px;

      .text {
        font-size: 14px;
      }

      .down-icon {
        margin-left: 10px;
      }

      .up-icon {
        margin-left: 10px;
      }
    }
  }
}
