.navbar-menu-drawer {
  padding: 40px 20px;
  background-color: #175c83;

  .menu-drawer-card {
    padding: 24px;
    border-radius: 24px;
    background-color: #fff;

    .title {
      margin-bottom: 20px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      text-align: center;
    }

    .profile {
      margin: auto;
      margin-bottom: 20px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 1px solid #175c83;
      padding: 1px;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid #175c83;
        object-fit: cover;
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #d9d9d9;
      margin: 20px 0;
    }

    .menu-drawer-btn {
      margin-bottom: 10px;
      width: 100%;

      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 13px;
      border-radius: 16px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;

      color: #175c83;
      border: 2px solid #175c83;
    }
  }
}
