.footer-component {
  background: linear-gradient(
      112.1deg,
      rgba(0, 0, 0, 0.2) 18.42%,
      rgba(0, 0, 0, 0) 99.67%
    ),
    #175c83;
  width: 100%;

  .main-wrapper {
    max-width: 1390px;
    padding: 40px 20px;
    margin: auto;
    margin: auto;
    display: flex;
    justify-content: space-around;

    .left {
      width: 22%;

      img {
        max-width: 100%;
        max-height: 140px;
        margin-top: -35px;
      }

      .para {
        margin-top: 20px;
        color: #fff;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }
    }

    .center {
      width: 40%;
      margin-top: 15px;
      display: flex;
      justify-content: space-evenly;

      .menu {
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;

        .link {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;

          display: flex;
          align-items: center;

          color: #ffffff;

          cursor: pointer;

          //margin-bottom: 25px;
          margin-bottom: 16px;
          margin-top: 4px;
          transition: all 0.1s ease-in-out;
        }
      }
    }

    .right {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .socialicons {
        img {
          width: 36px;
          height: 36px;
          margin-right: 12px;
          cursor: pointer;
        }
      }

      .bottom_wrapper {
        .text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
          width: 227px;
        }

        img {
          height: 32px;
          width: 32px;
          margin-top: 8px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .footer-component {
    .main-wrapper {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .left {
        max-width: 500px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .center {
        width: 100%;
        justify-content: space-evenly;
        margin: 50px 0 40px;
      }

      .right {
        width: 100%;
        align-items: center;

        .bottom_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          .text {
            margin: 40px 0;
            text-align: center;
            width: 200px;
          }
          img {
            height: 32px;
            width: 32px;
            margin: auto;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .footer-component {
    .main-wrapper {
      padding: 40px 10px;

      .left {
        max-width: 500px;
        width: 100%;
        display: block;
      }

      .center {
        margin: 40px 0 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .margin_left {
          margin-left: 46px;
        }

        .menu {
          width: 50%;

          .link {
            font-size: 14px;
          }
        }
      }

      .right {
        width: 100%;
        align-items: flex-start;

        .bottom_wrapper {
          align-items: flex-start;

          .text {
            margin: 0;
            margin-top: 60px;
            margin-bottom: 10px;
            text-align: start;
            width: 240px;
          }

          img {
            margin: 0;
          }
        }
      }
    }
  }
}
