.home-featured-section2 {
  max-width: 1390px;
  padding: 90px 20px;
  margin: auto;

  .main-title {
    font-family: "Poppins" !important;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
    padding-bottom: 70px;
  }

  .main-wrapper {
    margin-bottom: 50px;
    display: flex;
    gap: 25px;

    .left {
      width: 50%;
      border-radius: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

      .feature1 {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 25px 35px 20px 35px;
        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .brand {
            width: 250px;
            img {
              // max-width: 100%;
              width: 122px;
              height: 23px;
            }
            .items {
              font-weight: 400;
              margin-top: 4px;
              font-family: "Poppins";
              font-size: 14px;
              color: #000000;
            }
          }

          .view {
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
              margin-right: -5px;
            }
          }
        }

        .p_image {
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;

          img {
            max-width: 100%;
            // width: 60%;
            width: 50%;
          }
        }
      }
    }

    .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 25px;

      .feature2 {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        background: #ffffff;
        .image_icon {
          display: flex;
          .right_side {
            width: 55%;
          }
          .left_side {
            width: 45%;
          }
          .img {
            max-width: 50%;
            margin-top: 45px;
            margin-bottom: -102px;
          }
        }

        .f2-left {
          padding: 25px 35px 20px 35px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            font-family: "Poppins";
            font-weight: 500;
            font-size: 24px;
            color: #000000;
          }
          .items {
            font-family: "Poppins";
            font-weight: 400;
            font-size: 14px;
            color: #000000;
          }
          .view {
            display: flex;
            align-items: center;
            margin-top: 15px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
              margin-right: -5px;
            }
          }
        }
      }

      .sub-feature {
        display: flex;
        gap: 25px;

        .feature3 {
          width: 50%;
          border-radius: 20px;
          background: #ffffff;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;

          .bottom_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px 35px 20px 35px;
            .brand {
              .title {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                color: #000000;
              }

              .items {
                font-family: "Poppins";
                font-weight: 400;
                font-size: 14px;
                color: #000000;
              }
            }
            .view {
              display: flex;
              align-items: center;
              margin-top: 15px;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
              &:hover {
                margin-right: -5px;
              }
            }
          }

          .p_image {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              max-width: 50%;
              width: 100%;
            }
          }
        }

        .feature4 {
          width: 50%;
          border-radius: 20px;
          background: #ffffff;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;

          .p_image {
            margin: auto;
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              max-width: 50%;
              width: 100%;
            }
          }

          .f4_bottom_wraper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px 35px 20px 35px;
            .brand {
              .title {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 24px;

                color: #000000;
              }
              .items {
                font-family: "Poppins";
                font-weight: 400;
                font-size: 14px;
                color: #000000;
              }
            }
            .view {
              display: flex;
              align-items: center;
              margin-top: 15px;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
              &:hover {
                margin-right: -5px;
              }
            }
          }

          // .view {
          //   display: flex;
          //   align-items: center;

          //   margin-top: 15px;
          //   cursor: pointer;

          //   .text {
          //     font-family: "Poppins";
          //     font-weight: 400;
          //     font-size: 14px;
          //     color: #313133;
          //     margin-right: 15px;
          //   }
          // }
        }
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  .home-featured-section2 {
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .home-featured-section2 {
    .main-title {
      font-size: 24px;
      padding-bottom: 30px;
    }

    .main-wrapper {
      display: flex;
      flex-direction: column;

      .left {
        width: 100%;
        .feature1 {
          padding: 30px !important;
        }
      }

      .right {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .home-featured-section2 {
    padding: 70px 10px;

    .main-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .left {
        width: 100%;

        .feature1 {
          .brand {
            width: 100%;
          }

          .items {
            margin-top: 10px;
            font-size: 14px;
          }

          .p_image {
            // padding: 10px 0;

            img {
              max-width: 90%;
              width: 100%;
            }
          }
        }
      }

      .right {
        gap: 20px;

        .feature2 {
          flex-direction: column-reverse;
          border-radius: 20px;
          .image_icon {
            img {
              max-width: 90% !important;
            }
          }
          .f2-left {
            padding: 20px;
            width: 100%;
            display: flex;
            // flex-direction: column;
            justify-content: space-between;
            .items {
              font-size: 14px;
            }
            .title {
              font-size: 24px;
              line-height: 40px;
              color: #313133;
            }
          }

          .f2-right {
            padding-top: 10px;
            width: 100%;

            img {
              max-width: 70%;
              margin-left: 0px;
            }
          }
        }

        .sub-feature {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .feature3 {
            width: 100%;
            border-radius: 20px;
            .bottom_wrapper {
              padding: 0px 30px 30px 30px;
              margin-top: -30px;
              .brand {
                .title {
                  font-size: 24px;
                }
                .items {
                  font-size: 14px;
                }
              }
            }

            .p_image {
              height: unset;
              img {
                margin-left: 110px;
                max-width: 32% !important;
              }
            }
          }
          .feature4 {
            width: 100%;
            border-radius: 20px;
            .f4_bottom_wraper {
              padding: 0px 30px 30px 30px;
              margin-top: -25px;
              .brand {
                .title {
                  font-size: 24px;
                }
                .items {
                  font-size: 14px;
                }
              }
            }
            .p_image {
              height: unset;
              img {
                margin-left: 50px;
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
}
