// .ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-link::before{

// }

.product-listing-page
  .filter-wrapper
  .category-search
  .ais-Panel
  .ais-HierarchicalMenu
  .ais-HierarchicalMenu-list
  .ais-HierarchicalMenu-item--selected
  .ais-HierarchicalMenu-link::before {
  margin-top: -5px !important;
}

.product-listing-page
  .filter-wrapper
  .category-search
  .ais-Panel
  .ais-HierarchicalMenu
  .ais-HierarchicalMenu-list
  .ais-HierarchicalMenu-list--child {
  padding-left: 20px !important;
}

.ais-Pagination-item--selected {
  background-color: rgba(196, 196, 196, 0.6) !important;
}

.ais-Pagination-item--selected a {
  background-color: rgba(196, 196, 196, 0.6) !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 30px !important;
  display: flex !important;
  align-items: center !important;
  text-transform: capitalize !important;

  color: #313133 !important;

  opacity: 0.78 !important;
}

.ais-Pagination-item--page {
  background-color: transparent !important;
  border: 1px solid rgb(221, 221, 221);
}

.ais-Pagination-item--page a {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 30px !important;
  display: flex !important;
  align-items: center !important;
  text-transform: capitalize !important;
  color: #313133 !important;
}

.handleSwitchToggle {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 200px;

  .react-switch-bg {
    margin: 0 !important;
    height: 27px !important;
  }

  .react-switch-handle {
    height: 25px !important;
    width: 25px !important;
    border-radius: 50% !important;
  }

  .text {
    font-family: "Poppins";
    font-size: 14px;
    color: #000;
  }
}

.sort-select {
  width: 170px !important;
  vertical-align: middle;

  text-transform: capitalize;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  div {
    border-style: unset !important;
    border-width: unset !important;

    span {
      display: none !important;
    }
  }

  .css-1s2u09g-control {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 12px !important;
    background: transparent !important;

    &:hover {
      border: 1px solid #175c83 !important;
    }
  }
}

.product-listing-page-header {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;

  .go-back-component {
    padding: 0;
  }

  .ToggleSortSection-Web {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  @media screen and (max-width: 767px) {
    padding: 30px 0;

    .ToggleSortSection-Web {
      display: none;
    }
  }
}

.ToggleSortSection-Mobile {
  display: none;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 30px 0;

    .sort-select {
      width: 100% !important;
    }
  }
}

.product-listing-page {
  width: 100%;
  display: flex;
  padding-bottom: 80px;

  .filter-wrapper {
    min-width: 320px;
    width: 320px;
    padding: 10px;

    .filter-text {
      font-family: "Poppins";
      font-size: 24px;
      font-weight: 600;
      color: #000;
    }

    .result-text {
      font-family: "Poppins";
      font-size: 14px;
      color: #000;
      margin-bottom: 30px;
    }

    .input-search {
      width: 100%;
      height: 46px;

      overflow: hidden;
      position: relative;
      margin-bottom: 12px;

      background-color: #fff;
      // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);

      border-radius: 12px;

      display: flex;

      input {
        padding: 9px 16px;
        margin: 0;
        width: 100%;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        color: #666666;

        border: none;
        outline: none;
      }

      .search-icon-wrapper {
        background: #175c83;
        width: 45px;
        height: 100%;

        cursor: pointer;

        position: absolute;
        margin-left: auto;
        top: 0;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          margin-top: -5px;
          color: #fff;
        }

        &:hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.3),
              rgba(0, 0, 0, 0.3)
            ),
            #175c83;
        }
      }
    }

    /* Collapsible CSS */
    .collapsible {
      border-radius: 12px;
      background-color: #ffffff;
      border: 1px solid #fff;

      .section-header {
        display: flex;
        justify-content: space-between;

        outline: none !important;
        cursor: pointer;
        padding: 11px 20px;

        .section-title {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #313133;
        }

        .section-icon {
          font-size: 18px;
          margin-top: -3px;
        }
      }

      .content {
        padding-top: 20px;
      }
    }

    .collapsible:hover {
      border: 1px solid #175c83;
      border-radius: 12px;

      .section-header {
        .section-title {
          color: #175c83;
        }

        .section-icon {
          color: #175c83;
        }
      }
    }
    // .collapsible-active {
    //   border: 1px solid #175c83;
    //   border-radius: 12px;

    //   .section-header {
    //     .section-title {
    //       color: #175c83;
    //     }

    //     .section-icon {
    //       color: #175c83;
    //     }
    //   }
    // }

    .category-search {
      position: relative;
      max-width: 100%;
      background: #ffffff;
      border-radius: 12px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
      margin-bottom: 12px;

      .ais-Panel {
        padding: 0 20px;

        .ais-HierarchicalMenu {
          .ais-HierarchicalMenu-list {
            padding: 0;
            list-style-type: none;

            .ais-HierarchicalMenu-link {
              padding-bottom: 8px;
            }

            .ais-HierarchicalMenu-link:hover {
              color: #175c83;
            }

            .ais-HierarchicalMenu-label {
              font-size: 14px;
              font-family: "Poppins" !important;
            }

            .ais-HierarchicalMenu-count {
              font-size: 10px;
              letter-spacing: unset;
              padding: 0 4px;
            }

            .ais-HierarchicalMenu-link::before {
              content: "";
              width: 11px;
              height: 11px;
              border: 1px solid #c5c5c5;
              margin-right: 10px;
            }

            .ais-HierarchicalMenu-item--selected
              .ais-HierarchicalMenu-item:not(
                .ais-HierarchicalMenu-item--selected
              )
              .ais-HierarchicalMenu-link::before {
              border: 1px solid #c5c5c5;
              background-color: transparent;
            }

            .ais-HierarchicalMenu-item--selected
              .ais-HierarchicalMenu-link::before {
              border: 1px solid #175c83;
              background-color: #175c83;
            }

            .ais-HierarchicalMenu-list--child {
              padding-left: 11px;
            }
          }
        }
      }
    }

    .brand-search {
      position: relative;
      max-width: 100%;
      background: #ffffff;
      border-radius: 12px;

      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
      margin-bottom: 12px;

      .ais-Panel {
        padding: 0 20px;

        .ais-RefinementList {
          overflow: hidden;

          .ais-RefinementList-searchBox {
            .ais-SearchBox-form {
              margin-bottom: 8px;
            }

            input {
              padding: 9px;
              margin: 0;
              width: 100%;
              font-size: 14px !important;
              font-family: "Poppins" !important;
              font-weight: 400;
              color: black;
              border-radius: 4px;
              background-color: #fff;
              box-shadow: 0px 0px 0px;
              border: solid 1px;
              border-color: #d8d8d8;
              height: 32px;
              outline: none;
            }

            .ais-SearchBox-input {
              min-height: 0;
            }

            .ais-SearchBox-submit {
              display: none;
            }

            .ais-SearchBox-reset {
              display: none;
            }

            .ais-RefinementList-noResults {
              font-size: 14px !important;
              font-family: "Poppins" !important;
            }
          }

          .ais-RefinementList-list {
            max-height: 150px;
            overflow-x: hidden;
            overflow-y: scroll;
            padding: 0;
            list-style-type: none;
            margin-bottom: 10px;

            .ais-RefinementList-item {
              padding-bottom: 8px;

              .ais-RefinementList-labelText {
                font-size: 14px !important;
                font-family: "Poppins" !important;
              }

              .ais-Highlight,
              .ais-Highlight-highlighted,
              .ais-Highlight-nonHighlighted {
                font-size: 14px !important;
                font-family: "Poppins" !important;
              }

              .ais-RefinementList-count {
                font-size: 10px;
                letter-spacing: unset;
                padding: 0 4px;
              }

              .ais-RefinementList-checkbox {
                outline: none;
                width: 11px;
                height: 11px;
                border: 1px solid #c5c5c5;
                margin-right: 10px;
                background-color: transparent;
              }

              .ais-RefinementList-checkbox::after {
                width: 0px;
              }
            }

            .ais-RefinementList-item--selected .ais-RefinementList-checkbox {
              border: 1px solid #175c83;
              background-color: #175c83;
            }
          }
        }
      }
    }

    .file-type {
      position: relative;
      max-width: 100%;
      background: #ffffff;
      border-radius: 12px;

      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
      margin-bottom: 12px;

      .ais-Panel {
        padding: 0 20px;

        .ais-RefinementList {
          overflow: hidden;

          .ais-RefinementList-searchBox {
            .ais-SearchBox-form {
              margin-bottom: 8px;
            }

            input {
              padding: 9px;
              margin: 0;
              width: 100%;
              font-size: 14px !important;
              font-family: "Poppins" !important;
              font-size: 400;
              color: black;
              border-radius: 4px;
              background-color: #fff;
              box-shadow: 0px 0px 0px;
              border: solid 1px;
              border-color: #d8d8d8;
              height: 32px;
              outline: none;
            }

            .ais-SearchBox-input {
              min-height: 0;
            }

            .ais-SearchBox-submit {
              display: none;
            }

            .ais-SearchBox-reset {
              display: none;
            }

            .ais-RefinementList-noResults {
              font-size: 14px !important;
              font-family: "Poppins" !important;
            }
          }

          .ais-RefinementList-list {
            padding: 0;
            list-style-type: none;
            margin-bottom: 10px;

            .ais-RefinementList-item {
              padding-bottom: 8px;

              .ais-RefinementList-labelText {
                font-size: 14px !important;
                font-family: "Poppins" !important;
              }

              .ais-Highlight,
              .ais-Highlight-highlighted,
              .ais-Highlight-nonHighlighted {
                font-size: 14px !important;
                font-family: "Poppins" !important;
              }

              .ais-RefinementList-count {
                font-size: 10px;
                letter-spacing: unset;
                padding: 0 4px;
              }

              .ais-RefinementList-checkbox {
                outline: none;
                width: 11px;
                height: 11px;
                border: 1px solid #c5c5c5;
                margin-right: 10px;
                background-color: transparent;
              }

              .ais-RefinementList-checkbox::after {
                width: 0px;
              }
            }

            .ais-RefinementList-item--selected .ais-RefinementList-checkbox {
              background-color: #175c83;
              border: 1px solid #175c83;
            }
          }
        }
      }
    }

    .dimensions {
      position: relative;
      max-width: 100%;
      background: #ffffff;
      border-radius: 12px;

      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
      margin-bottom: 12px;

      .ais-Panel {
        padding: 0 26px 6px;
        border-bottom: 1px solid rgb(220, 220, 220);

        .ais-Panel-header {
          font-size: 14px;
          font-family: "Poppins" !important;
          line-height: 1.12em;
          border-radius: 3px;
          font-weight: 400;
          margin-top: 6px;

          letter-spacing: unset;
          color: #000;
          opacity: 0.9;
          padding: 0;
          text-transform: none;
        }

        .ais-Panel-body {
          padding-bottom: 15px;

          .ais-RangeSlider {
            margin-top: 2.5rem !important;
          }
        }
      }

      .ais-Panel:last-of-type {
        border-bottom: none;
      }
    }

    .rating {
      position: relative;
      max-width: 100%;
      background: #ffffff;
      border-radius: 12px;

      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
      margin-bottom: 12px;

      .ais-Panel {
        padding: 0 20px;

        .ais-RatingMenu-list {
          list-style-type: none;
          padding: 0;

          .ais-RatingMenu-item {
            padding-top: 2px;
            padding-bottom: 8px;
          }
        }
      }
    }

    .reset-button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .ais-ClearRefinements {
        width: 100%;

        .ais-ClearRefinements-button {
          width: 100%;
          height: 50px;

          background: #175c83;
          color: #ffffff;

          font-family: "Poppins" !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;

          border-radius: 16px;
          border: none;

          transition: all 0.3s ease-in-out;

          &:hover {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.3)
              ),
              #175c83;
          }
        }

        .ais-ClearRefinements-button--disabled {
          display: none;
        }
      }
    }
  }

  .product-section {
    padding-left: 20px;
    transition: all 0.3s ease;
    width: 100%;

    .refine-tag-wrapper {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      margin-left: 13px;

      .tags {
        width: max-content;
        display: flex;
        align-items: center;
        border: 1px solid #313133;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 3px 10px;
        margin-bottom: 10px;
        margin-left: -13px;

        .label {
          font-family: "Poppins" !important;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-transform: capitalize;
          color: #313133;
        }

        .cancel {
          cursor: pointer;
          font-size: 10px;
          color: #313133;
          margin-left: 10px;
          margin-top: 2px;
        }
      }
    }

    // .ais-Hits {
    //   width: 100%;

    //   .ais-Hits-list {
    //     display: grid;
    //     grid-template-columns: repeat(3, 1fr);
    //     width: 100%;
    //     grid-column-gap: 20px;
    //     grid-row-gap: 20px;
    //     padding: 0;
    //     margin: 0;
    //     list-style-type: none;

    //     @media screen and (max-width: 1200px) {
    //       grid-template-columns: repeat(2, 1fr);
    //     }

    //     @media screen and (max-width: 575px) {
    //       grid-template-columns: repeat(1, 1fr);
    //     }
    //   }

    //   .p_card {
    //     position: relative;
    //     background-color: #ffffff;
    //     border: 1px solid #fff;

    //     box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    //     border-radius: 20px;
    //     padding: 25px;
    //     margin: 0;

    //     width: 100% !important;
    //     // min-height: 400px !important;

    //     transition: all 0.3s ease;

    //     .heart {
    //       font-size: 1.2em;
    //       color: #175c83;
    //       cursor: pointer;
    //     }

    //     .p_body {
    //       width: 100%;
    //       margin-top: 5px;
    //       margin-bottom: 10px;

    //       .thumbnail {
    //         width: 100%;
    //         height: 160px;
    //         padding: 0 10px;

    //         display: flex;
    //         justify-content: center;
    //         align-items: center;

    //         img {
    //           max-width: 100%;
    //           max-height: 100%;
    //         }
    //       }
    //     }

    //     .brand {
    //       margin-bottom: 10px;
    //       width: 70px;

    //       img {
    //         max-width: 100%;
    //         width: 100%;
    //         height: 100%;
    //       }
    //     }

    //     .footer {
    //       display: flex;
    //       justify-content: space-between;
    //       align-items: flex-start;
    //       gap: 20px;

    //       .p_title {
    //         text-align: center;
    //         font-family: "Poppins";
    //         font-size: 14px;
    //         font-weight: 500;
    //         color: #313133;
    //         text-align: left;
    //         height: 40px;

    //         overflow: hidden;
    //         display: -webkit-box;
    //         -webkit-line-clamp: 2;
    //         -webkit-box-orient: vertical;
    //       }

    //       .download-btn {
    //         display: flex;
    //         align-items: center;
    //         gap: 10px;
    //         background-color: #175c83;
    //         padding: 4px 7px;
    //         border-radius: 6px;

    //         .icon {
    //           color: #fff;
    //           font-size: 20px;
    //         }
    //       }
    //     }

    //     &:hover {
    //       cursor: pointer;
    //       border: 1px solid #175c83;

    //       .p_title {
    //         text-decoration: none;
    //       }
    //     }
    //   }
    // }

    .ais-InfiniteHits-root {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      padding: 0;
      margin: 0;
      list-style-type: none;

      @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 575px) {
        grid-template-columns: repeat(1, 1fr);
      }

      .p_card {
        position: relative;
        background-color: #ffffff;
        border: 2px solid #fff;

        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        // padding: 25px;
        padding: 10px 15px;
        margin: 0;

        width: 100% !important;
        // min-height: 400px !important;

        transition: all 0.1s ease;

        .heart {
          font-size: 1.2em;
          color: #175c83;
          cursor: pointer;
        }

        .p_body {
          width: 100%;
          margin-top: 5px;
          margin-bottom: 10px;

          .thumbnail {
            width: 100%;
            height: 160px;
            padding: 0 10px;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
        
        .p_title {
          text-align: center;
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 500;
          color: #313133;
          text-align: center;
          height: 40px;
          margin-bottom: 5px; //new

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        // .brand {
        //   margin-bottom: 10px;
        //   width: 70px;

        //   img {
        //     max-width: 100%;
        //     width: 100%;
        //     height: 100%;
        //   }
        // }

        .footer {
          padding-top: 5px;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 20px;

          // .p_title {
          //   text-align: center;
          //   font-family: "Poppins";
          //   font-size: 14px;
          //   font-weight: 500;
          //   color: #313133;
          //   text-align: left;
          //   height: 40px;

          //   overflow: hidden;
          //   display: -webkit-box;
          //   -webkit-line-clamp: 2;
          //   -webkit-box-orient: vertical;
          // }

          // .emptyDiv {
          //   width: 30px;
          // }

          .brand {
            //margin-bottom: 10px;
            // width: 70px;
            width: 100px;
            height: 35px;
            display: flex;
            //justify-content: center;
            align-items: center;
            
  
            img {
              max-width: 100%;
              // width: 100%;
              // height: 100%;
              max-height: 100%;
            }
          }

          .download-btn {
            display: flex;
            align-items: center;
            gap: 10px;
            background: #175c83;
            padding: 4px 7px;
            border-radius: 6px;
            margin-top: 4px; //new

            .icon {
              color: #fff;
              font-size: 20px;
            }
          }
        }

        &:hover {
          cursor: pointer;
          border: 2px solid #175c83;
          //outline: 2px solid #175c83; /* The outline creates the border */

          .p_title {
            text-decoration: none;
          }

          .footer {
            .p_title {
              color: #175c83;
            }
          }
        }
      }
    }

    .ais-InfiniteHits-loadMore {
      margin-top: 60px;
      width: 100%;
      display: flex;
      justify-content: center;

      span {
        cursor: pointer;
        width: 280px;
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 13px;
        border-radius: 16px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;

        color: #175c83;
        border: 2px solid #175c83;
      }
    }

    .pagination-wrapper {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .pagination {
        .ais-Pagination {
          .ais-Pagination-list {
            display: flex;
            padding: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;

    .filter-wrapper,
    .product-section {
      width: 100%;
      min-width: auto;
      padding: 0;
    }
  }
}
