/* .product-listing-page
  .filter-wrapper
  .category-search
  .ais-Panel
  .ais-HierarchicalMenu
  .ais-HierarchicalMenu-list
  .ais-HierarchicalMenu-link::before {
  content: url("../../assets/images/other/arrow-small.png") !important;
  width: 10px !important;
  position: relative !important;
  top: 2px !important;
} */

/* .ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-link::before {
  transform: unset !important;
} */
.arrownexttwo {
  position: relative;
  width: 40px;
  margin-left: -18px;
}
.arrownextone {
  position: absolute;
  margin-top: 11px;
  margin-left: -4px;
  height: 15px;
  width: 10px;
}

.arrownexttwotwo {
  position: relative;
  width: 40px;
  margin-right: -18px;
  /* margin-left: -18px; */
  transform: rotate(180deg);
}
.arrownextonetwo {
  position: absolute;
  margin-top: 11px;
  margin-left: 14px;
  height: 15px;
  width: 10px;
  transform: rotate(180deg);
}

.search-icon {
  position: absolute;
  top: 12px;
  left: 16px;
}

.ais-RangeSlider .slider-rail {
  background-color: rgba(65, 66, 71, 0.08);
  border-radius: 3px;
  cursor: pointer;
  height: 3px;
  position: absolute;
  width: 100%;
}

.ais-RangeSlider .slider-track {
  background-color: #175c83;
  border-radius: 3px;
  cursor: pointer;
  height: 3px;
  position: absolute;
}

.ais-RangeSlider .slider-tick {
  cursor: grab;
  display: flex;
  font-size: 11px;
  font-weight: 500;
  position: absolute;
  text-align: center;
  top: -28px;
  transform: translateX(-50%);
  user-select: none;
}

.ais-RangeSlider .slider-handle {
  background-image: linear-gradient(to top, #f5f5fa, #fff);
  border-radius: 50%;
  box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),
    0 2px 3px 0 rgba(93, 100, 148, 0.2);
  cursor: grab;
  height: 16px;
  outline: none;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 16px;
  z-index: 1;
}

@media (max-width: 899px) {
  .ais-RangeSlider .slider-handle {
    height: 1.5rem;
    width: 1.5rem;
  }
}
