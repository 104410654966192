.go-back-component {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  color: #000;
  transition: all 0.1s ease;
  position: relative;
  max-width: max-content;

  .icon {
    transition: all 0.3s ease;
  }

  span {
    transition: all 0.3s ease;
    margin-left: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    // font-size: 21px;
    font-size: 16px;
    line-height: 24px;
  }

  &:hover {
    color: #175c83;

    .icon {
      margin-left: -5px;
    }

    span {
      margin-left: 15px;
    }
  }
}

@media screen and (max-width: 767px) {
  .go-back-component {
    padding: 30px 0;

    span {
      font-size: 18px;
    }
  }
}
