.home-manufacture-section {
  max-width: 1390px;
  padding: 70px 20px;
  margin: auto;

  .main-title {
    font-family: "Poppins" !important;
    font-size: 24px;
    font-weight: 700;
    text-transform: lowercase;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
    padding-bottom: 30px;
  }

  .brand-image-wrapper {
    margin: 40px 0;
    display: flex;
    justify-content: center;

    img {
      max-width: 80%;
    }
  }
}

@media screen and (max-width: 991px) {
  .home-manufacture-section {
    .main-title {
      font-size: 24px;
      padding-bottom: 30px;
    }
  }
}

@media screen and (max-width: 575px) {
  .home-manufacture-section {
    padding: 50px 10px;
  }
}
