.menu_container {
  padding: 0 80px 150px 80px;
  margin: auto;
}
.menu_back_btn {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.menu_heaing {
  font-size: 56px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.menu_why_bimroom {
  margin-top: 91px;
  background-image: url("../../assets/images/manufacture/whyroom.png"),
    linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 94.83%);
  height: 955px;
  background-position: left;
  /* background-size: 60%; */
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.menu_why_bimrom_point {
  font-size: 42px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.menu_why_bim_text {
  max-width: 591px;
  font-size: 21px;
  padding-top: 20px;
  z-index: 1;
  line-height: 32px;
  font-family: "Poppins", sans-serif;
}
.mbl_bimrom_text {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;

  padding-top: 10px;
  line-height: 25px;
}
.menu_cutom_feature_box {
  margin-top: 91px;
  background-image: linear-gradient(
      to right,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 94.83%
    ),
    url("../../assets/images/manufacture/cutom.png");

  height: 955px;
  background-position: right;

  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.arrange_space {
  padding-top: 94px !important;
}
.mbl_bimrom_text_box {
  margin-top: -120px;
}
.menu_traing_sec {
  margin-top: 91px;
  background-image: linear-gradient(
      to left,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 94.83%
    ),
    url("../../assets/images/manufacture/traing.png");

  height: 955px;
  background-position: left;
  background-size: 60%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.mbl_bimrom_heading {
  font-size: 32px;

  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.gg {
  background-image: linear-gradient(
      to top,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 94.83%
    ),
    url("../../assets/images/manufacture/whyBimromMbl.png");
  background-repeat: no-repeat;
  height: 393px;
  background-position: center;
  background-size: cover;
}
.hh {
  background-image: linear-gradient(
      to top,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 94.83%
    ),
    url("../../assets/images/manufacture/cutomFeatureMbl.png");
  background-repeat: no-repeat;
  height: 393px;
  background-position: center;
  background-size: cover;
}
.ii {
  background-image: linear-gradient(
      to top,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 94.83%
    ),
    url("../../assets/images/manufacture/traningMBl.png");
  background-repeat: no-repeat;
  height: 393px;
  background-position: center;
  background-size: cover;
}
.menu_cutom_text {
  max-width: 591px;
  font-size: 21px;
  z-index: 1;
  padding-top: 43px;
  line-height: 32px;
  font-family: "Poppins", sans-serif;
}
.menu_traing_text {
  max-width: 591px;
  font-size: 21px;
  padding-top: 33px;
  z-index: 1;
  line-height: 32px;
  font-family: "Poppins", sans-serif;
}
.menu_heaing_mbl {
  font-size: 42px;
  font-weight: 700;
  padding-top: 37px;
  line-height: 42px;
  font-family: "Poppins", sans-serif;
}
.menu_custom_box {
  padding-top: 60px;
}
.menu_why_bimrom_mbl_view {
  padding-top: 62px;
}
@media screen and (max-width: 1440px) {
  .menu_why_bimroom {
    margin-top: 61px;
    background-image: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 94.83%
      ),
      url("../../assets/images/manufacture/whyroom.png");

    height: 955px;
    background-position: left;
    background-size: 70%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .menu_traing_sec {
    margin-top: 91px;
    background-image: linear-gradient(
        to left,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 94.83%
      ),
      url("../../assets/images/manufacture/traing.png");
    height: 955px;
    background-position: left;
    background-size: 70%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .menu_cutom_feature_box {
    margin-top: 51px;
    background-image: linear-gradient(
        to right,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 94.83%
      ),
      url("../../assets/images/manufacture/cutom.png");

    height: 955px;
    background-position: right;
    background-size: 70%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .menu_why_bim_text {
    max-width: 591px;
    font-size: 18px;
    padding-top: 20px;
    z-index: 1;
    line-height: 27px;
    font-family: "Poppins", sans-serif;
  }
  .menu_container {
    padding: 0 20px 150px 20px;
    max-width: 1600px;
    margin: auto;
  }
  .menu_cutom_text {
    max-width: 500px;
    font-size: 18px;
    z-index: 1;
    padding-top: 33px;
    line-height: 27px;
    font-family: "Poppins", sans-serif;
  }
  .menu_why_bimrom_point {
    font-size: 32px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
  }
  .menu_heaing {
    font-size: 46px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
  }
}
@media screen and (max-width: 1000px) {
  .menu_why_bimroom {
    margin-top: 50px;
    background-image: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 94.83%
      ),
      url("../../assets/images/manufacture/whyroom.png");

    height: 955px;
    background-position: left;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .menu_cutom_feature_box {
    margin-top: -51px;
    background-image: linear-gradient(
        to right,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 94.83%
      ),
      url("../../assets/images/manufacture/cutom.png");

    height: 955px;
    background-position: right;
    background-size: 70%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .menu_traing_sec {
    margin-top: -150px;
    background-image: linear-gradient(
        to left,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 94.83%
      ),
      url("../../assets/images/manufacture/traing.png");
    height: 955px;
    background-position: left;
    background-size: 70%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .menu_cutom_feature_box {
    margin-top: 0px;
    background-image: linear-gradient(
        to right,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 94.83%
      ),
      url("../../assets/images/manufacture/cutom.png");

    height: 955px;
    background-position: right;
    background-size: 80%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .menu_cutom_text {
    max-width: 480px;
    font-size: 18px;
    z-index: 1;
    padding-top: 33px;
    line-height: 27px;
    font-family: "Poppins", sans-serif;
  }
  .menu_why_bim_text {
    max-width: 490px;
    font-size: 18px;
    padding-top: 20px;
    z-index: 1;
    line-height: 27px;
    font-family: "Poppins", sans-serif;
  }
  .menu_heaing {
    font-size: 46px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
  }
  .menu_container {
    padding: 0 16px 150px 16px;
    max-width: 1600px;
    margin: auto;
  }
  .menu_why_bimrom_point {
    font-size: 32px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
  }
  .menu_heaing {
    font-size: 42px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
  }
}
@media screen and (max-width: 768px) {
  .menu_why_bim_text {
    max-width: 490px;
    font-size: 16px;
    padding-top: 10px;
    z-index: 1;
    line-height: 23px;
    font-family: "Poppins", sans-serif;
  }
  .menu_cutom_text {
    max-width: 480px;
    font-size: 16px;
    z-index: 1;
    padding-top: 23px;
    line-height: 27px;
    font-family: "Poppins", sans-serif;
  }
  .menu_cutom_feature_box {
    margin-top: -121px;
    background-image: linear-gradient(
        to right,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 94.83%
      ),
      url("../../assets/images/manufacture/cutom.png");

    height: 955px;
    background-position: right;
    background-size: 70%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .line_break {
    display: none;
  }
  .menu_why_bimroom {
    margin-top: 20px;
    background-image: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 94.83%
      ),
      url("../../assets/images/manufacture/whyroom.png");

    height: 955px;
    background-position: left;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
@media screen and (max-width: 620px) {
  .menu_why_bimroom {
    display: none;
  }
  .menu_heaing {
    display: none;
  }
  .menu_cutom_feature_box {
    display: none;
  }
  .menu_traing_sec {
    display: none;
  }
}
@media screen and (min-width: 621px) {
  .menu_why_bimrom_mbl_view {
    display: none;
  }
  .menu_heaing_mbl {
    display: none;
  }
}
